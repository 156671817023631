import css from './Advantages.module.scss'
import {useEffect, useRef, useState} from "react";
import useVisible from "../../../hooks/useVisible";

const Advantages = ({data}) => {

    //animation
    const ref = useRef(null)
    const {isVisible} = useVisible(ref)
    //

    const [start, setStart] = useState(false)
    const [startTwo, setStartTwo] = useState(false)
    const [startThree, setStartThree] = useState(false)

    const [visible, setVisible] = useState(false)

   useEffect(()=> {
       isVisible && !visible && setVisible(true)
       setTimeout(()=> {
           setStartThree(isVisible);
       }, 200)
       setTimeout(()=> {
           setStartTwo(isVisible);
       }, 400)
       setTimeout(()=> {
           setStart(isVisible);
       }, 600)
    }, [isVisible])

    return (
        <div className={css.numerals} ref={ref}>

            {data && data.length > 0 && data.map(item => {
                switch (item.id) {
                    case 1:
                        return (
                            <div className={start ? `${css.item} ${css.entitled} ${css.animation}` :`${css.item} ${css.entitled}`} key={item.id}>
                                <div className={css.title}>{item.text}</div>
                                <div className={css.content}>
                                    <div className={css.numeral}>
                                        {item.number}
                                    </div>
                                    {/*<p className={css.text}>ориентировочно</p>*/}
                                </div>
                            </div>
                        )
                    case 2:
                        return (
                            <div className={ startTwo ? `${css.item} ${css.animation}` :`${css.item}`} key={item.id}>
                                <div className={css.content}>
                                    <div className={css.numeral}>
                                        {item.number}
                                    </div>
                                    <p className={css.text}>{item.text}</p>
                                    {visible && <img src="/files/images/main/main-metro.svg" className={`${css.img} ${css.second}`}
                                          alt={""} loading={"lazy"}/>}
                                </div>
                            </div>
                        )
                    case 3:
                        return (
                            <div className={ startThree ? `${css.item} ${css.animation}` :`${css.item}`}  key={item.id}>
                                <div className={css.content}>
                                    <div className={css.numeral}>
                                        {item.number}
                                    </div>
                                    <p className={css.text}>{item.text}</p>
                                    {visible && <img src="/files/images/main/main-embankment.svg"
                                          className={`${css.img} ${css.third}`} alt={""} loading={"lazy"}/>}
                                </div>
                            </div>
                        )
                    case 4:
                        return (
                            <div className={ isVisible ? `${css.item} ${css.animation}` :`${css.item}`}  key={item.id}>
                                <div className={css.content}>
                                    <div className={css.numeral}>
                                        {item.number}
                                    </div>
                                    <p className={css.text}>{item.text}</p>
                                    {visible && <img src="/files/images/main/main-kremlin.svg"
                                          className={`${css.img} ${css.fourth}`} alt={""} loading={"lazy"}/>}
                                </div>
                            </div>
                        )
                }
            })}
        </div>
    )
}

export default Advantages